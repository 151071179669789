import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAccounts, getAdaptations, getBackRemovals, getChannels, getCurrentPlan, getItems, getPricing, post, request } from "_redux/billing/billing.actions";
import { 
  selectAiImages,
  selectBillingAccounts,
  selectBillingAdaptations,
  selectBillingBackRemovals,
  selectBillingChannels,
  selectBillingCurrentPlan,
  selectBillingDateFilter,
  selectBillingHeros,
  selectBillingIsometrics,
  selectBillingItems,
  selectBillingWatermarks
} from "_redux/billing/billing.selector";
import dateFormatFilter from "_utils/format/dateFormatFilter";
import { reduxActions as BILLING } from "_constants/billing.constants";
import { API } from "_constants/request.constants";

const useBilling = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: currentPlanData, error: currentPlanError, status: currentPlanStatus } = useSelector(selectBillingCurrentPlan);
  const { data: accountsData, error: accountsError, status: accountsStatus } = useSelector(selectBillingAccounts);
  const { data: aiImagesData, error: aiImagesError, status: aiImagesStatus } = useSelector(selectAiImages);
  const { data: adaptationsData, error: adaptationsError, status: adaptationsStatus } = useSelector(selectBillingAdaptations);
  const { data: backRemovalsData, error: backRemovalsError, status: backgroundRemovalStatus } = useSelector(selectBillingBackRemovals);
  const { data: channelsData, error: channelsError, status: channelsStatus } = useSelector(selectBillingChannels);
  const { data: itemsData, error: itemsError, status: itemsStatus } = useSelector(selectBillingItems);
  const { data: herosData, error: herosError, status: herosStatus} = useSelector(selectBillingHeros);
  const { data: isometricsData, error: isometricsError, status: isometricsStatus} = useSelector(selectBillingIsometrics);
  const { data: watermarkData, error: watermarkError, status: watermarkStatus} = useSelector(selectBillingWatermarks);
  const dateFilter = useSelector(selectBillingDateFilter);

  const fetchAccounts = () => dispatch(getAccounts());
  const fetchAdaptations = (day, date) => dispatch(getAdaptations(day, date));
  const fetchAiImages = (day, filter) => {
    let data = {};
    let formated;
    if (day && filter) {
      formated = dateFormatFilter(day, filter.month, filter.year);
    } else {
      formated = dateFormatFilter(day, filter.month, filter.year);
    }

    data.w = {
      createdAt: formated
    };

    return dispatch(post(data, {
      endpoint: "/analytic/billing/layersAi",
      data,
      types: [
        BILLING.AI_IMAGES_STARTED,
        BILLING.AI_IMAGES_SUCCESS,
        BILLING.AI_IMAGES_FAIL
      ],
      feedback: {
        success: false,
        error: true
      }
    }, API));
  };
  const fetchBackRemovals = (day, date) => dispatch(getBackRemovals(day, date));
  const fetchPricing = () => dispatch(getPricing());
  const fetchChannels = () => dispatch(getChannels());
  const fetchCurrentPlan = () => dispatch(getCurrentPlan());
  const fetchItems = () => dispatch(getItems());

  const fetchHeros = (day, filter) => {
    let data = {};
    let formated;
    if (day && filter) {
      formated = dateFormatFilter(day, filter.month, filter.year);
    } else {
      formated = dateFormatFilter(day, filter.month, filter.year);
    }

    data.w = { createdAt: formated };

    return dispatch(post(data, {
      endpoint: "/analytic/billing/layersHeros",
      types: [
        BILLING.HEROS_STARTED,
        BILLING.HEROS_SUCCESS,
        BILLING.HEROS_FAIL
      ]
    }, API));
  };


  const fetchIsometrics = (day, filter) => {
    let data = {};
    let formated;
    if (day && filter) {
      formated = dateFormatFilter(day, filter.month, filter.year);
    } else {
      formated = dateFormatFilter(day, filter.month, filter.year);
    }

    data.w = { createdAt: formated };

    return dispatch(post(data, {
      endpoint: "/analytic/billing/layersIsometrics",
      data,
      types: [
        BILLING.ISOMETRICS_STARTED,
        BILLING.ISOMETRICS_SUCCESS,
        BILLING.ISOMETRICS_FAIL
      ]
    }, API));
  };


  const fetchWatermarks = (day, filter) => {
    let data = {};
    let formated;
    if (day && filter) {
      formated = dateFormatFilter(day, filter.month, filter.year);
    } else {
      formated = dateFormatFilter(day, filter.month, filter.year);
    }

    data.w = { createdAt: formated };

    return dispatch(post(data, {
      endpoint: "/analytic/billing/layersWatermarks",
      types: [],
      data,
      types: [
        BILLING.WATERMARKS_STARTED,
        BILLING.WATERMARKS_SUCCESS,
        BILLING.WATERMARKS_FAIL
      ]
    }, API));
  };


  const getNextRenewal = (billingDay = 1) => {
    const date = new Date();

    const day = date.getDay();
    let month = date.getMonth();
    let year = date.getFullYear();

    if (day >= parseInt(billingDay)) {
      date.setMonth(month + 1);
      month = date.getMonth();
      year = date.getFullYear();
    }

    return `${billingDay} ${t(`common:datetime.shortMonth.${month+1}`)} ${year}`;
  };

  const getOptionsFilter = (billingDay) => {
    const date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let options = [];

    if (parseInt(billingDay) >= 10) {
      if (month === 12) {
        month = 1;
        year += 1;
      } else {
        month += 1;
      }
    }
    
    for (let i = 0; options.length < 13; i++) {
      options.push({
        month: month,
        year: year
      });
      
      if (month === 1) {
        month = 12;
        year -= 1;
      } else {
        month -= 1;
      }
    }
    
    return options;
  };

  return {
    accountsData,
    accountsError,
    accountsStatus,
    adaptationsData,
    adaptationsError,
    adaptationsStatus,
    aiImagesData,
    aiImagesError,
    aiImagesStatus,
    backRemovalsData,
    backRemovalsError,
    backgroundRemovalStatus,
    channelsData,
    channelsError,
    channelsStatus,
    currentPlanData,
    currentPlanError,
    currentPlanStatus,
    dateFilter,
    fetchAccounts,
    fetchAdaptations,
    fetchAiImages,
    fetchBackRemovals,
    fetchPricing,
    fetchChannels,
    fetchCurrentPlan,
    fetchHeros,
    fetchIsometrics,
    fetchItems,
    fetchWatermarks,
    getNextRenewal,
    getOptionsFilter,
    herosData,
    herosError,
    herosStatus,
    isometricsData,
    isometricsError,
    isometricsStatus,
    itemsData,
    itemsError,
    itemsStatus,
    watermarkData,
    watermarkError,
    watermarkStatus
  };
};

export default useBilling;
